import React, { Suspense } from 'react';
import dynamic from 'next/dynamic';
import Icon from '../../components/Icon/Icon';
const Image = dynamic(() => import('../../components/Image/Image'));
import LanguageSelection from '../../components/LanguageSelection/LanguageSelection'
import { stripHtml } from '../../adapters/helpers/Utils';
import LoginNavigationBlock from '../../components/LoginNavigationBlock/LoginNavigationBlock';
import { Constants } from './Constants';
import Loader from './Loader';
export default class HierarchyUtils {

  static getLevel(hierarchyDoc) {
    if (!hierarchyDoc?.fields?.level) {
      return;
    }
    return hierarchyDoc.fields.level;
  }

  static renderHierarchy(hierarchy) {
    const destinations = hierarchy.fields.destinations;
    let title = hierarchy.fields.title;
    return (
      <div>
        <Suspense fallback={<Loader/>}>
          {title && this.renderHierarchyTitle(hierarchy, title)}
        </Suspense>
        <Suspense fallback={<Loader/>}>
          {destinations &&
            <ul id={this.getHierarchyId(hierarchy)}>
              {destinations.map((d, index) => (
                <li key={index}>{this.renderDestination(d)}</li>
              ))}
            </ul>
          }
        </Suspense>
      </div>
    )
  }

  static renderDestination(dest, callback = null, icon = null, onClick, className = null, privacyLink) {
    const type = dest.fields.contentType;
    switch (type) {
      case 'pageLink':
        return this.renderPageLink(dest, icon, onClick, className);
      case 'hierarchy':
        dest.fields.level = 'child';
        return callback ? callback() : this.renderHierarchy(dest);
      case 'link':
        return this.renderLink(dest, icon, onClick, className, privacyLink);
      case 'languageSelectionBlock':
        return <LanguageSelection {...dest} />;
      case 'loginNavigationBlock':
        return <LoginNavigationBlock document={dest} />;
      default:
        return 'Unsupported Type : ' + type;
    }
  }

  static renderPageLink(pageLink, icon = null, onClick, className = null) {
    const link = pageLink.fields.pagePath.fields.slug;
    return this.renderLinkAndTitle(pageLink.fields, link, icon, onClick, className);
  }

  static renderLink(link, icon = null, onClick, className = null, privacyLinkObj = {}) {
    const url = link?.fields?.url === Constants.PrivacyLinkPlaceholder ? (privacyLinkObj?.privacy || link?.fields?.fallBackUrl) : link?.fields?.url;
    return this.renderLinkAndTitle(link.fields, url, icon, onClick, className);
  }

  static renderLinkAndTitle(link, url, icon = null, onClick, className = null) {
    let classNames = '';
    if (link?.linkClassName) {
      classNames = link?.linkClassName
    }
    if (className) {
      classNames += ` ${className}`;
    }
    let currentPage = className && className.indexOf('is-active') > -1 ? true : false;
    return (
      <a href={url} data-action-detail={stripHtml(link.dataActionDetail ? link.dataActionDetail : link.title)}
        aria-label={stripHtml(link.ariaLabel || link.title)}
        onClick={onClick} target={link?.type === 'external' ? '_blank' : undefined}
        rel={link?.type === 'external' ? 'noopener noreferrer' : undefined} className={classNames}>
            {link.image && this.renderImage(link.image)}
        <span>
        {link?.isArrowRequired && icon && icon?.position === 'before' && <Icon {...icon} /> }
          {
            currentPage ?
              <span aria-current={'page'} dangerouslySetInnerHTML={{ __html: link.title }}></span> :
              <span dangerouslySetInnerHTML={{ __html: link.title }}></span>
          }
          {icon && (icon.position === 'after' || icon.position === '') && <Icon {...icon} />}
        </span>      
      </a>
    )
  }

  static renderImage(image) {
    return (
      <Image noLazyLoad={true} image={image} ariaHidden={true} />
    )
  }

  static getHierarchyId(hierarchyDoc) {
    let id = hierarchyDoc.fields.name;
    id = id.replace(/ /g, '-');
    return id;
  }

  static renderHierarchyTitle(hierarchy, title) {
    return (
      <div dangerouslySetInnerHTML={{ __html: title }}></div>
    );
  }
}

import '@babel/polyfill';
import React, { Fragment, Suspense, lazy } from 'react'
import Router from 'next/router';
import Block from '../adapters/helpers/Block';
import PropTypes from 'prop-types';
import contentfulClient from '../adapters/model/ContentfulClient';
import { campaignOverlayStatus } from '../adapters/helpers/Utils';

const Meta = lazy(() => import('../components/Meta/Meta'));
const GTMScriptFunction = lazy(() => import('../components/GTMScriptFunction'));
const Loader = lazy(() => import('../adapters/helpers/Loader'));


class Page extends React.Component {

  componentDidMount() {
    Router.beforePopState(({ as }) => {
      location.href = as;
    });
  }

  static async getInitialProps({ asPath }) {
    const client = await contentfulClient.getClient();
    const locales = await client.getLocales();
    const defaultLocale = locales.items.find(locale => locale.default);
    const languageCode = defaultLocale.code.substring(0, 2);
    const countryCode = defaultLocale.code.slice(-2);

    const baseProps = await this.initPage(asPath);
    baseProps.languageCode = languageCode;
    baseProps.countryCode = countryCode;

    return {
      ...baseProps
    }
  }

  static async initPage(slug) {
    let page = {};

    // Errors pages doesn't have a page entry in Contentful CMS
    if (slug) {
      const { default: pageDao } = await import('Model/dao/PageDao');
      page = await pageDao.getActivePageBySlug(slug);
    }

    page?.fields?.blocks[0]?.fields?.blocks[1]?.fields?.blocks?.map((blocksArr) => {
      if (blocksArr?.fields?.devicesConfigurations) {
        blocksArr?.fields?.devicesConfigurations.map((devices) => {
          if (devices.fields.backgroundAsset && devices.fields.backgroundAsset.fields) {
            devices.fields.backgroundAsset.fields.isBannerImage = true;
          }
        })
      }
      if (blocksArr?.fields?.backgroundAsset && blocksArr?.fields?.backgroundAsset?.fields) {
        blocksArr.fields.backgroundAsset.fields.isBannerImage = true;
      }
      if (blocksArr?.fields?.mobileBackgroundAsset && blocksArr?.fields?.mobileBackgroundAsset?.fields) {
        blocksArr.fields.mobileBackgroundAsset.fields.isBannerImage = true;
      }
      if (blocksArr?.fields?.mobileBackgroundImage && blocksArr?.fields?.mobileBackgroundImage?.fields) {
        blocksArr.fields.mobileBackgroundImage.fields.isBannerImage = true;
      }
    })

    return { 'page': page };
  }

  constructor(props) {
    super(props);
  }

  /**
   * Rendering of the page. This always render the page using a common layout.
   *
   * Override the render method to do otherwise.
   *
   * @returns {*}
   */
  render() {
    let page = this.getPage();
    const pagePath = page?.slug?.fields?.slug;
    let metaData = this.getMetadata();
    if (metaData) {
      metaData.pageType = 'page'
    }
    const isDisableCookieConsent = page?.disableCookieConsent;
    return (
      <Suspense fallback={<Loader />}>
        <Fragment>
          <Suspense fallback={<Loader />}>
            <Meta metadata={metaData} pagePath={pagePath} />
          </Suspense>
          <div className="ob-wrapper">
            <Suspense fallback={<Loader />}> {this.renderBody()}</Suspense>
          </div>
          <Suspense fallback={<Loader />}>
            <GTMScriptFunction languageCode={this.props.languageCode} countryCode={this.props.countryCode} productVariant={undefined} isDisableCookieConsent={isDisableCookieConsent} />
          </Suspense>
        </Fragment>
      </Suspense>
    )
  }

  getPage() {
    return this.props?.page?.fields;
  }

  getMetadata() {
    campaignOverlayStatus(this.props?.page?.fields?.isEnableCampaignOverlay);
    return this.props?.page?.fields?.metadata?.fields;
  }

  /**
   * By default, the page will render the modules of the page.
   * Can be overridden on the child class to do specific rendering.
   * @returns {*|void}
   */
  renderBody() {
    return new Block(this.props.page).renderChildBlocks(null, null, null, this.props?.page?.fields?.lazyloadIndexValue ? this.props?.page?.fields?.lazyloadIndexValue : 1);
  }
}

Page.propTypes = {
  page: PropTypes.object,
  languageCode: PropTypes.string,
  countryCode: PropTypes.string
}

export default Page

import React from 'react';
import { InstantSearch } from 'react-instantsearch-dom';
import { getSearchClient } from '../adapters/algolia/client';

import App from 'next/app';
import '../styles/index.scss';


class CustomApp extends App {
    render() {
        const { Component, pageProps } = this.props;
        const searchClient = getSearchClient();
        return (
            <InstantSearch searchClient={searchClient} indexName={process.env.ALGOLIA_INDEX}>
                    <Component {...pageProps} />
            </InstantSearch>
        )
    }
}

export default CustomApp